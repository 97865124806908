import React from "react"
import browserLang from "browser-lang"
import { withPrefix } from "gatsby"
import { IntlProvider } from "react-intl"
import {IntlContextProvider, IntlLanguageMapContext} from "./intl-context"
import {transform} from "lodash";

const preferDefault = (m) => (m && m.default) || m

const polyfillIntl = (language) => {
  // const locale = language.split("-")[0]
  // try {
  //   if (!Intl.PluralRules) {
  //     require("@formatjs/intl-pluralrules/polyfill")
  //     require(`@formatjs/intl-pluralrules/locale-data/${locale}`)
  //   }
  //
  //   if (!Intl.RelativeTimeFormat) {
  //     require("@formatjs/intl-relativetimeformat/polyfill")
  //     require(`@formatjs/intl-relativetimeformat/locale-data/${locale}`)
  //   }
  // } catch (e) {
  //   throw new Error(`Cannot find react-intl/locale-data/${language}`)
  // }
}

const withIntlProvider = (intl, langUrls) => (children) => {
  polyfillIntl(intl.language)
  return (
    <IntlProvider
      locale={intl.language}
      defaultLocale={intl.defaultLanguage}
      messages={intl.messages}
    >
      <IntlLanguageMapContext.Provider value={langUrls}>
        <IntlContextProvider value={intl}>{children}</IntlContextProvider>
      </IntlLanguageMapContext.Provider>
    </IntlProvider>
  )
}

export default ({ element, props }, pluginOptions) => {
  if (!props) {
    return
  }

  const { pageContext, location } = props
  const { defaultLanguage, langUrls } = pluginOptions
  const { intl, sourcedLangUrls } = pageContext
  const { language, languages, redirect, routed, originalPath, languageMappings } = intl

  if (typeof window !== "undefined") {
    window.___gatsbyIntl = intl
  }
  /* eslint-disable no-undef */
  const isRedirect = redirect && !routed

  const finalLangUrls = [...langUrls, ...(sourcedLangUrls || [])].map(langUrl => {
    return {
      path: langUrl.path,
      urls: transform(langUrl.urls, (r,v,k) => {
        r[k] = `/${k}${v}`
      })
    }
  })

  if (isRedirect) {
    const { search } = location

    // Skip build, Browsers only
    if (typeof window !== "undefined") {
      let detected =
        window.localStorage.getItem("gatsby-intl-language") ||
        browserLang({
          languages,
          fallback: language,
        })

      if (!languages.includes(detected)) {
        detected = language
      }

      const queryParams = search || ""

      let newUrl;
      if (languageMappings) {
        newUrl = withPrefix(`${languageMappings[detected]}${queryParams}`)
      } else {
        newUrl = withPrefix(`/${detected}${originalPath}${queryParams}`)
      }

      window.localStorage.setItem("gatsby-intl-language", detected)
      window.location.replace(newUrl)
    }
  }

  const renderElement = isRedirect
    ? GATSBY_INTL_REDIRECT_COMPONENT_PATH &&
      React.createElement(
        preferDefault(require(GATSBY_INTL_REDIRECT_COMPONENT_PATH))
      )
    : element
  return withIntlProvider(intl, finalLangUrls)(renderElement)
}
