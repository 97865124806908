exports.components = {
  "component---25dc6fbe-2de9-44a0-b23e-00f8fc029890": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---25dc6fbe-2de9-44a0-b23e-00f8fc029890" */),
  "component---30e75e60-dfbd-4d28-b903-6b3dcab61240": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---30e75e60-dfbd-4d28-b903-6b3dcab61240" */),
  "component---51031ba2-445a-4d04-8655-ab37d36be1b4": () => import("./../../../src/pages/app/[...].tsx" /* webpackChunkName: "component---51031ba2-445a-4d04-8655-ab37d36be1b4" */),
  "component---5bb41e4d-8eed-429e-8486-bcfc36437403": () => import("./../../../src/content/markdown/templates/basic.tsx?__contentFilePath=/app/src/content/markdown/imprint/imprint_de.mdx" /* webpackChunkName: "component---5bb41e4d-8eed-429e-8486-bcfc36437403" */),
  "component---68543d40-094f-4f0c-9c8b-867332234b00": () => import("./../../../src/content/markdown/templates/basic.tsx?__contentFilePath=/app/src/content/markdown/imprint/imprint_en.mdx" /* webpackChunkName: "component---68543d40-094f-4f0c-9c8b-867332234b00" */),
  "component---893486b7-86da-4cd0-8713-910cfe313265": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---893486b7-86da-4cd0-8713-910cfe313265" */),
  "component---9fdbee41-25fe-4648-9d9e-2ac451e3bf24": () => import("./../../../src/pages/app/discovery.tsx" /* webpackChunkName: "component---9fdbee41-25fe-4648-9d9e-2ac451e3bf24" */),
  "component---b95002fe-62ad-4d9e-bc01-1c718e0c0afa": () => import("./../../../src/content/markdown/templates/basic.tsx?__contentFilePath=/app/src/content/markdown/privacy/privacy_en.mdx" /* webpackChunkName: "component---b95002fe-62ad-4d9e-bc01-1c718e0c0afa" */),
  "component---c3189eeb-6fb5-4c67-aec4-675b9f8cef64": () => import("./../../../src/content/markdown/templates/basic.tsx?__contentFilePath=/app/src/content/markdown/privacy/privacy_de.mdx" /* webpackChunkName: "component---c3189eeb-6fb5-4c67-aec4-675b9f8cef64" */),
  "component---c95ab025-5956-478b-88ae-40450ebe3db5": () => import("./../../../src/content/strapi/best-influencer-cities/best-influencer-city.tsx" /* webpackChunkName: "component---c95ab025-5956-478b-88ae-40450ebe3db5" */),
  "component---fd8c5ae4-af50-4394-8852-640840c5273e": () => import("./../../../src/pages/dev-404-page.tsx" /* webpackChunkName: "component---fd8c5ae4-af50-4394-8852-640840c5273e" */)
}

