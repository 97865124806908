import React from "react"
import PropTypes from "prop-types"
import { Link as GatsbyLink, navigate as gatsbyNavigate } from "gatsby"
import {IntlContextConsumer, IntlLanguageMapContext} from "./intl-context"

const Link = React.forwardRef(
  ({ to, language, children, onClick, ...rest }, ref) => (
      <IntlLanguageMapContext.Consumer>
        {(langMap) =>
          <IntlContextConsumer>
            {(intl) => {
              const languageLink = language || intl.language

              function escapeRegex(string) {
                return string.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');
              }

              const mapHit = langMap?.find(e => to.match(new RegExp(`^${escapeRegex(e.path)}?`)));
              let link;
              if (! mapHit) {
                link = intl.routed || language ? `/${languageLink}${to}` : `${to}`
              } else {
                link = mapHit.urls[languageLink]
              }

              const handleClick = (e) => {
                if (language) {
                  localStorage.setItem("gatsby-intl-language", language)
                }
                if (onClick) {
                  onClick(e)
                }
              }

              return (
                <GatsbyLink {...rest} to={link} onClick={handleClick} ref={ref}>
                  {children}
                </GatsbyLink>
              )
            }}
          </IntlContextConsumer>
        }
      </IntlLanguageMapContext.Consumer>
  )
)

Link.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  language: PropTypes.string,
}

Link.defaultProps = {
  to: "",
}

export default Link

export const navigate = (to, options) => {
  if (typeof window === "undefined") {
    return
  }

  const { language, routed } = window.___gatsbyIntl
  const link = routed ? `/${language}${to}` : `${to}`
  gatsbyNavigate(link, options)
}

export const changeLocale = (language, to) => {
  if (typeof window === "undefined") {
    return
  }
  const { routed, languageMappings } = window.___gatsbyIntl

  const removePrefix = (pathname) => {
    const base =
      typeof __BASE_PATH__ !== `undefined` ? __BASE_PATH__ : __PATH_PREFIX__
    if (base && pathname.indexOf(base) === 0) {
      pathname = pathname.slice(base.length)
    }
    return pathname
  }

  const removeLocalePart = (pathname) => {
    if (!routed) {
      return pathname
    }
    const i = pathname.indexOf(`/`, 1)
    return pathname.substring(i)
  }

  const pathname =
    to || removeLocalePart(removePrefix(window.location.pathname))

  if (languageMappings) {
    const link = `${languageMappings[language]}${window.location.search}`
    localStorage.setItem("gatsby-intl-language", language)
    gatsbyNavigate(link)
  } else {
    // TODO: check slash
    const link = `/${language}${pathname}${window.location.search}`
    localStorage.setItem("gatsby-intl-language", language)
    gatsbyNavigate(link)
  }

}
