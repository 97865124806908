import React, {HTMLAttributes, useEffect, useState} from "react";
import * as styles from "./front-submenu.module.scss"
import classNames from "classnames";
import {usePrevious} from "../../common/hooks/use-previous";

interface FrontSubmenuOptions {
    children;
    navOpen: boolean;
    upperRounded: boolean
}

const FrontSubmenu = ({children, navOpen, upperRounded}: FrontSubmenuOptions) => {
    
    const prevOpenState = usePrevious(navOpen);
    const [hidden, setHidden] = useState(true);
    const [open, setOpen] = useState(false);
    
    
    useEffect(() => {
        if (prevOpenState !== navOpen) {
            setTimeout(() => setOpen(navOpen), 10)
            setHidden(!navOpen)
        }
    }, [navOpen])
    
    return (
        <div className={classNames(styles.wrapper, {[styles.upperrounded]: upperRounded}, {[styles.open]: open, [styles.hidden]: hidden})}>
            {children}
        </div>
    )
}

export default FrontSubmenu
