import "./front-layout.scss";
import React, {startTransition, useEffect, useState} from "react";
import FrontHeader from "../front-header/front-header";
import FrontFooter from "../front-footer/front-footer";
import {useMediaQuery} from "react-responsive";
import {MenuState} from "../front-header/menu-state.enum";
import layout from "@/components/layout/layout";

// SSR Compability
export const useIsMobile = () => {
    const shouldBeMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        setIsMobile(shouldBeMobile)
    }, [shouldBeMobile])
    return isMobile;
}

const initContext = {
    menuState: MenuState.AUTO,
    setMenuState: (state: MenuState) => {}
}
export const LayoutContext = React.createContext(initContext);
export const useLayoutContext = () => React.useContext(LayoutContext);

const FrontLayout = ({children, pageContext}) => {
    
    const [layoutState, setLayoutState] = useState<typeof initContext>({
        menuState: pageContext.initMenuState as MenuState,
        setMenuState: (menuState) => {
            setLayoutState(currentState => {
                if (menuState !== currentState.menuState) {
                    return ({...currentState, menuState})
                } else return currentState;
            })
        }
    })
    
    useEffect(() => {
        layoutState.setMenuState(pageContext.initMenuState)
    }, [pageContext.initMenuState])
    
    return (
        
        <main style={{paddingTop: '30px', backgroundColor: '#fff'}}>
            <LayoutContext.Provider value={layoutState}>
                <FrontHeader menuState={layoutState.menuState} />
                    {children}
                <FrontFooter />
            </LayoutContext.Provider>
        </main>
        
    )
    
}

export default FrontLayout;
