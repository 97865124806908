import wrapPage from "./wrap-page"
export const wrapPageElement = wrapPage

export const wrapRootElement = ({element}) => {
    require("@formatjs/intl-pluralrules/polyfill");
    require("@formatjs/intl-pluralrules/locale-data/de");
    require("@formatjs/intl-pluralrules/locale-data/en");
    require("@formatjs/intl-relativetimeformat/polyfill");
    require("@formatjs/intl-relativetimeformat/locale-data/de");
    require("@formatjs/intl-relativetimeformat/locale-data/en");
}
