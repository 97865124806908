import React, {useContext, useEffect, useRef, useState} from "react"
import * as styles from "./front-header.module.scss";
import classNames from "classnames";

import Logo from "/src/resources/images/header-logo.svg"
import FrontSubmenu from "../front-submenu/front-submenu";
import {debounce} from "lodash";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCompass, faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons'
import {useClickOutsideContainer} from "../../common/hooks/use-click-outside-container";
import {Link, useIntl} from "@p/gatsby-plugin-intl-custom";
import {globalHistory, useLocation} from '@reach/router'
import {LayoutContext, useIsMobile} from "../front-layout/front-layout";
import {MenuState} from "./menu-state.enum";
import {useClickAway} from "@uidotdev/usehooks";
import {useTrans} from "@/app/components/trans";

const useNavigationBehaviour = () => {
    const [menuOpen, setMenuOpen] = useState<boolean>(false)
    
    const navRef = useClickAway(e => {
        // only when not wrapped in navigation
        if (!(e.target as any).closest('.navigation')) {
            setMenuOpen(false);
        }
    })
    
    useEffect(() => {
        const dbounce = debounce(handleMoveOutside, 50, {maxWait: 50})
        function handleMoveOutside(event) {
            if (navRef.current
                && !navRef.current.contains(event.target)
                && event.clientY > 200) {
                setMenuOpen(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousemove", dbounce);
        
        const unsubscribe = globalHistory.listen(({action}) => {
            if (action == 'PUSH') {
                setMenuOpen(false)
            }
        })
        
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousemove", dbounce);
            dbounce.cancel();
            unsubscribe();
        };
    }, [navRef.current])
    
    
    return [navRef, menuOpen, setMenuOpen as any] ;
}

const FrontHeader: React.FC<{menuState: MenuState}> = ({menuState}) => {
    const [
        menuRef,
        navSolutionsOpen,
        setNavSolutionsOpen
    ] = useNavigationBehaviour()
    
    const {trans} = useTrans();
    
    const navRef = useRef<HTMLElement|any>(null);
    const [isSticky, setSticky] = useState<boolean>(false);
    const [isAutoSizeSmall, setIsAutoSizeSmall] = useState<boolean>(false);
    const [transitionEnabled, setTransitionEnabled] = useState(false)
    
    let shouldAutoSizeSmall = () => false;
    if (typeof window != 'undefined') {
        shouldAutoSizeSmall = () => isAutoSizeSmall
            ? window.scrollY > (navRef.current?.getBoundingClientRect().height || 0) - 35
            : window.scrollY > (navRef.current?.getBoundingClientRect().height || 0)
    }
    
    useEffect(() => {
        const onScroll = () => {
            if (menuState == MenuState.AUTO) {
                if (isAutoSizeSmall !== shouldAutoSizeSmall()) {
                    setIsAutoSizeSmall(shouldAutoSizeSmall())
                }
            } else {
                setIsAutoSizeSmall(false)
            }
        }
        const bounce = debounce(onScroll, 50, {maxWait: 50});
        window.addEventListener('scroll', bounce)
        onScroll();
        return () => {
            window.removeEventListener('scroll', bounce);
            bounce.cancel();
        }
    }, [isAutoSizeSmall, menuState])
    
    useEffect(() => {
        setSticky(menuState == MenuState.AUTO)
    }, [menuState])
    
    useEffect(() => {setTimeout(() => setTransitionEnabled(true), 100)})
    
    // useEffect(() => {
    //     // Server side rendering macht stress weil er vorher die klassen
    //     // vom Nav braucht (und somit die höhe nicht immer richtig ist)
    //     window.onbeforeunload = function () {
    //         if (window.scrollY > 200) {
    //             history.scrollRestoration = "manual";
    //             window.scrollBy({top: 12, behavior: "instant"})
    //         }
    //     }
    // })
    
    const isMobile = useIsMobile()
    
    return (
        <div ref={navRef} className={classNames(styles.host, {
            [styles.sticky]: isSticky,
            [styles.shrink]: menuState == MenuState.SMALL || isAutoSizeSmall,
            [styles.transitions]: transitionEnabled,
            [styles.hasShadow]: isAutoSizeSmall
        })}>
            <nav className="container-xl d-flex h-100">
                <div className="d-flex align-items-center w-100 position-relative justify-content-between">
                    <div className={classNames(styles.logo)}>
                        <Link to="/">
                            <Logo height="100%" width="100%"></Logo>
                        </Link>
                    </div>
                    
                    <div className={classNames("navigation d-flex text-uppercase ms-0 ms-lg-8", styles.navigationItemsWrapper)}>
                        
                        <div className="me-4 me-sm-7 pointer">
                            <a onMouseOver={() => !isMobile ? setNavSolutionsOpen(true) : null}
                               onClick={(event) => setNavSolutionsOpen(!navSolutionsOpen)}
                               className={classNames(styles.link, {[styles.active]: navSolutionsOpen})}
                               href="#">
                                <span>{trans({id: 'FRONT.NAV.SOLUTIONS'})}</span>
                            </a>
                        </div>
                        <div className="pointer">
                            <Link className={classNames(styles.link)} to="/about-us">
                                <span>{trans({id: 'FRONT.NAV.ABOUT_US'})}</span>
                            </Link>
                        </div>
                    </div>
                    
                    <div className="bottom-marker" ref={menuRef}>
                        <FrontSubmenu navOpen={navSolutionsOpen} upperRounded={! isAutoSizeSmall}>
                            <div className="d-flex flex-row">
                                <div className="w-50 p-4">
                                    <span className="text-black text-opacity-50 text-uppercase fw-medium">{trans({id: 'FRONT.NAV.SOLUTIONS'})}</span>
                                    <Link to="/app/discovery" className="text-decoration-none text-black">
                                        <div className="d-flex flex-row bg-white hover user-select-none pointer p-3 mt-2 border-radius-1">
                                            <div className="me-3 fs-4"><FontAwesomeIcon icon={faMagnifyingGlass} /></div>
                                            <div>
                                                <div className="fw-bold mb-1">{trans({id: 'FRONT.NAV.DISCOVERY_HEADING'})}</div>
                                                <div className="text-accent fs-7">{trans({id: 'FRONT.NAV.DISCOVERY_DESCRIPTION'})}</div>
                                            </div>
                                        </div>
                                    </Link>
                                    {/*<div className="d-flex flex-row bg-white hover user-select-none p-3 border-radius-1">*/}
                                    {/*    <div className="me-3 fs-4"><FontAwesomeIcon icon={faCompass} /></div>*/}
                                    {/*    <div>*/}
                                    {/*        <div className="fw-bold mb-1">Reachout <span className="badge bg-secondary ms-2 text-uppercase">Upcoming</span></div>*/}
                                    {/*        <div className="text-accent fs-7">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.</div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                                <div className="w-50 p-4" style={{backgroundColor: 'var(--bs-gray-100)'}}>
                                    <span className="text-black text-opacity-50 text-uppercase fw-medium">{trans({id: 'FRONT.NAV.TOP_INFLUENCER_CITYS'})}</span>
                                    <ul className="list-group mt-2">
                                        <Link to="/best-influencer-cities/berlin">
                                            <li className="list-group-item bg-white bg-opacity-50 border-black border-opacity-10 hover user-select-none pointer d-flex flex-row justify-content-between"><span>Berlin</span><span className="d-none d-md-inline">{trans({id: 'STATIC.ISO3166_MAP.DEU'})}</span></li>
                                        </Link>
                                        <Link to="/best-influencer-cities/cologne">
                                            <li className="list-group-item bg-white bg-opacity-50 border-black border-opacity-10 hover user-select-none pointer d-flex flex-row justify-content-between"><span>{trans({id: 'STATIC.IMPORTANT_CITY.COLOGNE'})}</span><span className="d-none d-md-inline">{trans({id: 'STATIC.ISO3166_MAP.DEU'})}</span></li>
                                        </Link>
                                        <Link to="/best-influencer-cities/dusseldorf">
                                            <li className="list-group-item bg-white bg-opacity-50 border-black border-opacity-10 hover user-select-none pointer d-flex flex-row justify-content-between"><span>{trans({id: 'STATIC.IMPORTANT_CITY.DUSSELDORF'})}</span><span className="d-none d-md-inline">{trans({id: 'STATIC.ISO3166_MAP.DEU'})}</span></li>
                                        </Link>
                                        <Link to="/best-influencer-cities/frankfurt">
                                            <li className="list-group-item bg-white bg-opacity-50 border-black border-opacity-10 hover user-select-none pointer d-flex flex-row justify-content-between"><span>{trans({id: 'STATIC.IMPORTANT_CITY.FRANKFURT'})}</span><span className="d-none d-md-inline">{trans({id: 'STATIC.ISO3166_MAP.AUT'})}</span></li>
                                        </Link>
                                        <Link to="/best-influencer-cities/hamburg">
                                            <li className="list-group-item bg-white bg-opacity-50 border-black border-opacity-10 hover user-select-none pointer d-flex flex-row justify-content-between"><span>{trans({id: 'STATIC.IMPORTANT_CITY.HAMBURG'})}</span><span className="d-none d-md-inline">{trans({id: 'STATIC.ISO3166_MAP.CHE'})}</span></li>
                                        </Link>
                                        <Link to="/best-influencer-cities/munich">
                                            <li className="list-group-item bg-white bg-opacity-50 border-black border-opacity-10 hover user-select-none pointer d-flex flex-row justify-content-between"><span>{trans({id: 'STATIC.IMPORTANT_CITY.MUNICH'})}</span><span className="d-none d-md-inline">{trans({id: 'STATIC.ISO3166_MAP.DEU'})}</span></li>
                                        </Link>
                                        <Link to="/best-influencer-cities/vienna">
                                            <li className="list-group-item bg-white bg-opacity-50 border-black border-opacity-10 hover user-select-none pointer d-flex flex-row justify-content-between"><span>{trans({id: 'STATIC.IMPORTANT_CITY.VIENNE'})}</span><span className="d-none d-md-inline">{trans({id: 'STATIC.ISO3166_MAP.DEU'})}</span></li>
                                        </Link>
                                        <Link to="/best-influencer-cities/zurich">
                                            <li className="list-group-item bg-white bg-opacity-50 border-black border-opacity-10 hover user-select-none pointer d-flex flex-row justify-content-between"><span>{trans({id: 'STATIC.IMPORTANT_CITY.ZURICH'})}</span><span className="d-none d-md-inline">{trans({id: 'STATIC.ISO3166_MAP.DEU'})}</span></li>
                                        </Link>
                                    </ul>
                                </div>
                            </div>
                        </FrontSubmenu>
                    </div>
                    
                    <div className="ms-0 ms-lg-auto d-none d-md-block">
                        <Link to="/app/discovery">
                            <button className="btn btn-primary btn-lg">{trans({id: 'FRONT.NAV.BUTTON_SEARCH_INFLUENCER'})}</button>
                        </Link>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default FrontHeader;
