import React, {forwardRef, PropsWithChildren, ReactElement, ReactNode, useEffect, useRef, useState} from "react";
import Select from "react-select";
import {Props} from "react-select";
import classNames from "classnames";
import {Controller, Control, useController} from "react-hook-form";
import {useClickAway} from "@uidotdev/usehooks";

interface SelectTextProps {
    fixedContextMenuWidth?: number,
    
    options: { value: any, label: string }[],
    value?: any
    onChange?: (data) => void
}

const SelectText: React.FC<
    & Omit<Props, 'options'>
    & SelectTextProps
    & Omit<React.HTMLAttributes<any>, 'children'>
    & {children?: ((render) => ReactNode)}
> = forwardRef(({
        children,
        fixedContextMenuWidth = 200,
        value,
        onChange,
        options = [],
        defaultValue,
        className,
        menuPlacement,
        ...rest
    }, ref) => {
    
    const [menuOpen, setMenuOpen] = useState(false);
    const [innerValue, setInnerValue] = useState(value);
    useEffect(() => setInnerValue(value), [value])
    
    const clickOutsideRef = useClickAway<HTMLDivElement>((e) => {
        if (!(e.target as any).closest('button')) {
            setMenuOpen(false)
        }
    });
    
    const Control = ({menuIsOpen}) => (
        <></>
    )
    
    const innerRefValue: any = options.find(option => option?.value == innerValue?.value);
    
    return (
        <span className="position-relative">
            <span>{children(
                <button onClick={() => setMenuOpen(p => !p)}
                        type="button"
                        className={classNames(
                            "btn btn-link text-decoration-none m-0 p-0 lh-1 align-baseline",
                            className)}>
                    {(innerRefValue?.label) || 'none'}
                </button>
            )}</span>
            
            <div className={classNames({
                "top-marker": menuPlacement == 'top',
                "bottom-marker": !menuPlacement}
            )} ref={clickOutsideRef}>
                <div className={classNames(
                    "bg-white text-black border-radius-1 position-absolute mt-2 z-3",
                    {'d-none': ! menuOpen}
                )} style={{width: fixedContextMenuWidth}}>
                    
                    <Select
                        menuPosition="absolute"
                        menuPlacement={menuPlacement}
                        components={{Control}}
                        menuIsOpen={menuOpen}
                        value={value}
                        onChange={(data) => {
                            setInnerValue(data);
                            if (onChange) onChange(data)
                        }}
                        ref={ref as any}
                        defaultValue={defaultValue}
                        options={options}
                        closeMenuOnSelect={true}
                        onMenuClose={() => setMenuOpen(false)}
                        {...rest} />
                        
                </div>
            
            </div>
        </span>
    )
    
})

export default SelectText;
