import "./layout.scss";
import React from "react";
import FrontLayout from "../front-layout/front-layout";
import {Script} from "gatsby";

const Layout = ({children, pageContext}) => {
    return <FrontLayout pageContext={pageContext}>
        {children}
    </FrontLayout>
    
}

export default Layout;
