import React from "react";
import * as styles from "./front-footer.module.scss"
import {StaticImage} from "gatsby-plugin-image";

import Logo from "../../resources/images/logo-only.svg"
import Munich from "../../resources/images/text.svg"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faLocationPin} from "@fortawesome/free-solid-svg-icons";
import {Link} from "@p/gatsby-plugin-intl-custom";
import SelectText from "@/app/components/select-text/select-text";
import {useTrans} from "@/app/components/trans";
import {changeLocale} from "@p/gatsby-plugin-intl-custom";

const FrontFooter = () => {
    
    const {locale, trans} = useTrans();
    
    return (
        <footer className="bg-dark text-white position-relative overflow-hidden"
                style={{
                    minHeight: 900,
                    background: 'linear-gradient(black, #111213)'
                }}>
            
            <div className="container-xl gx-5 z-2 position-relative">
                
                <div className="row mt-4 mt-md-8 mb-6">
                    
                    <div className="col-12 col-md-4 d-flex flex-column align-items-center">
                        
                        <Logo height="200" width="200" viewBox="0 0 700 700"></Logo>
                        
                        <div className="fs-3 mt-5">{trans({id: 'FRONT.FOOTER.QUESTIONS', defaultMessage: 'Du hast fragen?'})}</div>
                        <div className="mt-1 text-accent">{trans({id: 'FRONT.FOOTER.QUESTION_SUB', defaultMessage: 'Kontaktiere uns gerne.'})}</div>
                        
                        <div className="d-flex flex-row flex-md-column mt-3 mt-md-0">
                        
                            <div className="d-flex flex-row mt-4 me-5 me-md-0 align-items-center">
                                <div style={{width: 65, height: 65, fontSize: '2rem'}}
                                     className="d-flex justify-content-center align-items-center rounded-circle text-center text-white-50 fs-2 bg-gray-900">
                                    <FontAwesomeIcon width={25} height={25} icon={faEnvelope} />
                                </div>
                                <div className="ms-3">
                                    <p className="text-accent">{trans({id: 'FRONT.FOOTER.LABEL_MAIL', defaultMessage: 'E-Mail'})}</p>
                                    <p><a className="text-decoration-none text-white" href="mailto:info@influace.de">info@influace.de</a></p>
                                </div>
                            </div>
                            
                            <div className="d-flex flex-row mt-4 align-items-center">
                                <div style={{width: 65, height: 65, fontSize: '2rem'}}
                                     className="d-flex justify-content-center align-items-center rounded-circle text-center text-white-50 fs-2 bg-gray-900">
                                    <FontAwesomeIcon width={25} height={25} icon={faLocationPin} />
                                </div>
                                <div className="ms-3">
                                    <p className="text-accent">{trans({id: 'FRONT.FOOTER.LABEL_LOCATION', defaultMessage: 'Standort'})}</p>
                                    <p>{trans({id: 'STATIC.IMPORTANT_CITY.MUNICH'})}</p>
                                </div>
                            </div>
                        
                        </div>
                        
                        
                    </div>
                    
                    
                    <div className="col-12 col-md-4 mt-5 mt-md-0 d-flex flex-column align-items-center">
                        
                        <p className="fw-bold mb-4">{trans({id: 'FRONT.FOOTER.PRODUCTS_HEADER', defaultMessage: 'Produkte'})}</p>
                        <p className="my-3">
                            <Link className="opacity-50 text-white text-decoration-none" to="/app/discovery">
                                {trans({id: 'FRONT.FOOTER.PRODUCTS_SEARCH_INFLUENCER', defaultMessage: 'Influencer Suche'})}
                            </Link>
                        </p>
                        <p className="my-3 opacity-50">
                            {trans({id: 'FRONT.FOOTER.PRODUCTS_CAMPAIGN_PLANNING', defaultMessage: 'Kampagnenplanung'})} <span className="badge bg-primary">Soon</span>
                        </p>
                        
                    </div>
                    
                    <div className="col-12 col-md-4 d-flex flex-column align-items-center mt-5 mt-md-0">
                        
                        <p className="fw-bold mb-4">Beste Influencer Städte</p>
                        {[{name: 'Berlin', uuid: '60817ad6947822c'},
                            {name: trans({id: 'STATIC.IMPORTANT_CITY.MUNICH'}), uuid: '94984ef38934af3'},
                            {name: trans({id: 'STATIC.IMPORTANT_CITY.HAMBURG'}), uuid: '3502130e406abc6'},
                            {name: trans({id: 'STATIC.IMPORTANT_CITY.VIENNE'}), uuid: 'f730b77c35a7a35'},
                            {name: trans({id: 'STATIC.IMPORTANT_CITY.ZURICH'}), uuid: 'f9b5a3aca711b0e'},
                            {name: trans({id: 'STATIC.IMPORTANT_CITY.DUSSELDORF'}), uuid: 'c691874b1c6cee3'},
                            {name: trans({id: 'STATIC.IMPORTANT_CITY.COLOGNE'}), uuid: 'bd29d22bb4f9eda'},
                            {name: trans({id: 'STATIC.IMPORTANT_CITY.FRANKFURT'}), uuid: '600ed9e12744255'}].map(e => (
                            <p key={e.name} className="my-3">
                                <Link className="opacity-50 text-white text-decoration-none" to={`/app/discovery?city=${e.uuid}`}>
                                    {e.name}
                                </Link>
                            </p>
                        ))}
                    
                    </div>
                    
                </div>
                
                <div className="row user-select-none">
                    <div className="col-12">
                        <hr />
                        © 2023, InfluACE.
                        <div className="fa-pull-right">
                            <SelectText
                                menuPlacement="top"
                                value={{value: locale}}
                                className="text-white"
                                onChange={(e) => changeLocale(e.value)}
                                options={[
                                    {label: 'Deutsch', value: 'de'},
                                    {label: 'English', value: 'en'},
                                ]}>
                                {render => <span className="me-5"><span className="color-alternative-4 me-1">Sprache:</span> {render}</span>}
                            </SelectText>
                            
                            <Link to="/imprint">Impressum</Link> | <Link to="/privacy">Datenschutz</Link>
                        </div>
                    </div>
                </div>
                
            </div>
            <div className="position-absolute w-100 bottom-0 z-0">
                <StaticImage src="../../resources/images/footer.png"
                             alt="footer-bg"
                             placeholder="blurred"
                             style={{minHeight: 500}}
                             formats={['avif', 'png']}
                             layout="fullWidth" />
            </div>

        </footer>
    )
}

export default FrontFooter
