module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/app/src/components/layout/layout"},
    },{
      plugin: require('../plugins/gatsby-plugin-intl-custom/gatsby-browser.js'),
      options: {"plugins":[],"path":"/app/src/lang","languages":["en","de"],"defaultLanguage":"de","redirect":true,"langUrls":[{"path":"/about-us/","urls":{"de":"/ueber-uns/","en":"/about-us/"}}],"langPageNamespaces":{"/app/discovery/":["FRONT.*","APP.*","STATIC.*"]}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
